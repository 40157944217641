import { useUserStore } from "~/store/userStore";
import { useMaintenanceStore } from "~/store/maintenanceStore";
import md5 from "md5";

const requestOptions = {
  credentials: "include",
  onRequestError() {
    useNuxtApp().$toast.error(_T("@Internal server error"));
  },
  onResponse({ response }) {
    const xBuildtag = response.headers.get("x-build-tag");

    if (null !== xBuildtag && xBuildtag !== "B2C_151224") {
      reloadNuxtApp();
    }

    response._data = response._data.data ?? response._data;
  },
  onResponseError({ response }) {
    const userStore = useUserStore();

    if (response.status === 401) {
      userStore.fetch();
    }

    // if (!error.config.isAuthorized) {
    //
    // } else {
    //

    if (response.status === 429) {
      useNuxtApp().$toast.error(_T("@Number of requests exceeded"));
    }

    if (response.status === 503) {
      useMaintenanceStore().fetch();
    }

    if (response.status === 422) {
      return navigateTo({ name: getLocalizedRouteName("home") });
    }
  },
};

export const useAsyncPrivateAPI = async (url, options) => {
  const baseUrl = "/api";

  return useMyFetch(baseUrl + url, options);
};

export const useAsyncAPI = async (url, options) => {
  const config = useRuntimeConfig();
  const baseUrl = config.public.apiBaseUrl + "/user";

  return useMyFetch(baseUrl + url, options);
};

export const useMyFetch = async (url, options) => {
  const { data, status, error } = await useFetch(url, {
    ...options,
    ...requestOptions,
  });

  const hash = ref(null);

  if (options?.showError && null !== error.value) {
    throw showError({ statusCode: 404, statusMessage: "request error" });
  }

  if (options?.withHash) {
    hash.value = md5(JSON.stringify(data.value));
  }

  return reactive({
    data,
    status,
    hash,
  });
};

export const useAPI = (url, options) => {
  return $fetch(url, {
    ...options,
    ...requestOptions,
    baseURL: useRuntimeConfig().public.apiBaseUrl + "/user",
  });
};

export const useStrapiApi = (url, options, showError) => {
  return $fetch(url, {
    ...options,
    baseURL: useRuntimeConfig().public.strapiBaseUrl + "/api",
  }).catch((error) => {
    if (!showError) {
      throw error;
    }

    useNuxtApp().$toast.error(_T("@Internal server error"));
  });
};

export const useAsyncStrapiApi = (key, url, options) => {
  return useAsyncData(key, () => {
    return useStrapiApi(url, options);
  });
};
